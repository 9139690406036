<template>
    <div>
        <div class="create_alert_new_entities_middle_section">
            <!-- alert name -->
            <mds-layout-grid>
                <mds-row class="create_alerts_name_section">
                    <mds-col :cols="4">
                        <mds-form>
                            <mds-fieldset>
                                <mds-input
                                    maxlength="250"
                                    label="Alert Name"
                                    placeholder="Enter a descriptive name"
                                    v-model.trim="alertName"
                                    :disabled="alertInputField"
                                ></mds-input>
                            </mds-fieldset>
                        </mds-form>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <mds-form>
                <mds-fieldset class="create_alert_wrapper">
                    <div class="create_alert_checkbox">Level</div>
                    <mds-checkbox
                        name="group1"
                        v-for="(levels, index) in EntityLevels"
                        :checked="levels.isSelected"
                        :key="index"
                        :value="toString(levels.entitytypeid)"
                        :label="levels.entitytypedescription"
                        @change="selectLevel($event, levels)"
                    ></mds-checkbox>
                </mds-fieldset>
            </mds-form>

            <!-- combo box for domicile -->
            <mds-form class="create_alert_comboBox">
                <mds-combo-box
                    :key="domicileKey"
                    placeholder="Select text"
                    label="Domicile"
                    v-model="selectComboValue"
                    multiple
                    :multipleItemLimit="2"
                    :data-set="dropdownDomicileList"
                ></mds-combo-box>
            </mds-form>

            <span v-if="isAdminUser">
                <CreateAlertsEmailComponent
                    :AddedEmailList="AddedEmailList"
                ></CreateAlertsEmailComponent>
            </span>
        </div>
        <!-- footer section -->
        <common-footer
            buttonName="Save"
            @cancelButton="cancelButton"
            @saveButton="saveButton"
            :isDisabled="!saveButtonDisable"
        >
        </common-footer>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import MdsCheckbox from "@mds/checkbox";
import MdsForm from "@mds/form";
import MdsFieldset from "@mds/fieldset";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsComboBox from "@mds/combo-box";
import CommonFooter from "../../data-dissemination/components/commonFooter.vue";
import CreateAlertsEmailComponent from "../components/CreateAlertsEmailComponent.vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsInput from "@mds/input";

export default {
    name: "CreateAlertsNewEntities",
    components: {
        MdsCheckbox,
        MdsForm,
        MdsFieldset,
        LoaderComponent,
        MdsComboBox,
        CommonFooter,
        CreateAlertsEmailComponent,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsInput,
    },
    data() {
        return {
            showLoader: true,
            toggleCancelButton: false,
            alertName: "",
            selectComboValue: [],
            AddedEmailList: [],
            alertInputField: false,
            domicileKey: 1,
        };
    },
    props: {
        EntityLevels: {
            type: Array,
            default: () => [],
        },
        dropdownDomicileList: {
            type: Array,
            default: () => [],
        },
        selectLevelId: {
            type: Array,
            default: () => [],
        },
        getEditAlertData: {
            type: Object,
            default: () => {},
        },
        isAdminUser: {
            type: Boolean,
            default: false,
        },
    },
    async mounted() {
        if (Object.keys(this.getEditAlertData).length) {
            this.alertInputField = true;
            this.alertName = this.getEditAlertData.name;
            if (this.dropdownDomicileList.length != 0) {
                this.selectComboValue = this.getEditAlertData.config.domicile;
            }
            if (this.getEditAlertData.email != null) {
                let ccResult = this.getEditAlertData.email.cc;
                if (ccResult != null) {
                    ccResult.forEach((item) => {
                        let data = {};
                        (data.Email = item),
                            (data.Type = "Cc"),
                            this.AddedEmailList.push(data);
                    });
                }

                let toResult = this.getEditAlertData.email.to;
                if (toResult != null) {
                    toResult.forEach((item) => {
                        let data = {};
                        (data.Email = item),
                            (data.Type = "To"),
                            this.AddedEmailList.push(data);
                    });
                }
            }
            this.domicileKey += 1;
        } else {
            if (sessionStorage.getItem("permissions")) {
                var permissions = JSON.parse(
                    sessionStorage.getItem("permissions")
                );
                let arr = permissions.userEmail;
                let data = {};
                (data.Email = arr),
                    (data.Type = "To"),
                    this.AddedEmailList.push(data);
            }
        }
        this.showLoader = false;
    },
    computed: {
        saveButtonDisable() {
            return (
                this.alertName &&
                this.selectComboValue.length &&
                this.selectLevelId.length &&
                this.AddedEmailList.length
            );
        },
    },
    methods: {
        selectLevel(event, level) {
            this.$emit("selectLevel", event, level);
        },

        // save button
        saveButton() {
            if (Object.keys(this.getEditAlertData).length) {
                this.$emit(
                    "updateAlert",
                    this.alertName,
                    this.selectComboValue,
                    this.AddedEmailList
                );
            } else {
                this.$emit(
                    "saveAlert",
                    this.alertName,
                    this.selectComboValue,
                    this.AddedEmailList
                );
            }
        },
        // cancel button in footer
        cancelButton() {
            this.toggleCancelButton = !this.toggleCancelButton;
            this.$router.push('/settings/alerts');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

fieldset.create_alert_wrapper {
    display: inline-block;
}
.create_alert_checkbox {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-1-x;
    font-size: 16px;
    font-weight: bold;
}

.create_alert_comboBox {
    margin-top: $mds-space-2-x;
    width: 500px;
}

.create_alert_new_entities_middle_section {
    height: 650px;
}

.create_alerts_name_section {
    margin-top: $mds-space-2-x;
}
</style>