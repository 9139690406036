<template>
    <div>
        <CreateAlerts
            v-if="checkAlertEdit"
            :alertHeaderTitle="alertHeaderTitle"
            :getAdminAlertType="getAdminAlertType"
            :getAlertType="getAlertType"
            :getEditAlertData="getEditAlertData"
            :alertId="alertId"
        ></CreateAlerts>
    </div>
</template>

<script>
import CreateAlerts from "../components/CreateAlerts.vue";
import {mapState } from "vuex";
import get from "lodash/get";
import { ALERTS_ACTIONS } from "@/store/modules/alerts/contants";


export default {
    name: "CreateAlertsContainer",
    components: {
        CreateAlerts,
    },
    data() {
        return {
            showLoader: false,
            alertHeaderTitle: "Create Alert",
            getAdminAlertType: [
                {
                    id: 1,
                    lable: "Alert you when items change",
                },
                {
                    id: 2,
                    lable: "Alert you when new entities are added",
                },
            ],
            getAlertType: [
                {
                    id: 1,
                    lable: "Alert you when items change",
                },
                {
                    id: 2,
                    lable: "Alert you when new entities are added",
                },
            ],
            getEditAlertData: {},
            alertId: 0,
        };
    },

    props: {},

    async mounted() {
        const notificationRuleId = await this.$route.query.notificationRuleId;
        if (notificationRuleId != undefined) {
            this.alertId = parseInt(notificationRuleId);
            await this.$store.dispatch(ALERTS_ACTIONS.FETCH_EDIT_ALERT, {
                notificationRuleId,
            });
            let response = this.alertEdit;
            this.alertHeaderTitle = "Edit Alert Name: " + response.name;
            this.getEditAlertData = response;
        }
    },

    computed: {
        
        ...mapState({
            alertEdit(state) {
                return get(state, "alerts.editAlerts.editAlerts", []);
            },
        }),
        checkAlertEdit() {
            if (this.$route.query.notificationRuleId) {
                return Object.keys(this.getEditAlertData).length;
            } else {
                return true;
            }
        },
    },

    methods: {},
};
</script>
