<template>
    <div id="CreateAlertsTPADelivery">
        <div>
            <div class="create_alert_section_border"></div>
            <mds-layout-grid class="Create_alert_Email_section">
                <mds-row class="Create_alert_Email_section_add_email_section">
                    <mds-col :cols="4">
                        <mds-form>
                            <mds-fieldset>
                                <mds-input
                                    maxlength="250"
                                    label="Add Email"
                                    placeholder="Input Email"
                                    @focus="triggerInputField('email')"
                                    :error="email.error"
                                    :error-text="[email.errorMsg]"
                                    v-model.trim="email.data"
                                ></mds-input>
                            </mds-fieldset>
                        </mds-form>
                    </mds-col>
                    <span>
                        <mds-form>
                            <mds-fieldset>
                                <span>
                                    <mds-radio-button
                                        class="radioButtonGroup"
                                        name="group"
                                        v-for="emailType in getEmailType"
                                        :key="emailType.id"
                                        v-model="switchEmailId"
                                        :value="emailType.id"
                                        :label="emailType.lable"
                                        @input="switchEmailTypes(emailType)"
                                    ></mds-radio-button>
                                </span>
                            </mds-fieldset>
                        </mds-form>
                    </span>

                    <mds-col :cols="2" class="create_alert_addButton">
                        <mds-button
                            variation="secondary"
                            @click="addEmail(email)"
                            :disabled="!disableAddButton"
                        >
                            Add
                        </mds-button>
                    </mds-col>
                </mds-row>
                <div class="create_alert_shared_with_heading">Recipients</div>
                <mds-row class="create_alert_emailList">
                    <mds-table row-hover class="create_email_alert_table">
                        <mds-thead>
                            <mds-th style="width: 80%"></mds-th>
                            <mds-th style="width: 15%"></mds-th>
                            <mds-th style="width: 5%"></mds-th>
                        </mds-thead>
                        <mds-tbody>
                            <mds-tr
                                v-for="(item, i) in AddedEmailList"
                                :key="i"
                            >
                                <mds-td class="tableDataRow">{{
                                    item.Email
                                }}</mds-td>
                                <mds-td class="tableDataRowType">{{
                                    item.Type
                                }}</mds-td>
                                <mds-td class="tableDataRow">
                                    <!-- Remove single value from selected list -->
                                    <template>
                                        <span
                                            ><mds-button
                                                @click="removeSingleEmail(item)"
                                                class="actionButton"
                                                icon="trash"
                                                variation="icon-only"
                                                type="button"
                                            ></mds-button
                                        ></span>
                                    </template>
                                </mds-td>
                            </mds-tr>
                        </mds-tbody>
                    </mds-table>
                </mds-row>
            </mds-layout-grid>
        </div>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import MdsFieldset from "@mds/fieldset";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsRadioButton from "@mds/radio-button";
import { MdsButton } from "@mds/button";
import { validateSingleEmail } from "@/helpers/validator.js";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";

export default {
    name: "CreateAlertsEmailComponent",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsInput,
        MdsFieldset,
        LoaderComponent,
        MdsRadioButton,
        MdsButton,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
    },
    data() {
        return {
            showLoader: true,
            email: {
                data: "",
                error: false,
                errorMsg: "Enter valid email with max length 60 characters",
            },
            switchEmailType: "",
            switchEmailId: 0,
            toggleCancelButton: false,
            saveButtonDisable: false,
            getEmailType: [
                {
                    id: 1,
                    lable: "To",
                },
                {
                    id: 2,
                    lable: "Cc",
                },
            ],
        };
    },
    props: {
        AddedEmailList: {
            type: Array,
            default: () => [],
        },
    },
    async mounted() {
        this.showLoader = false;
    },
    computed: {
        disableAddButton() {
            return this.switchEmailType && this.email.data;
        },
    },
    methods: {
        switchEmailTypes(type) {
            this.switchEmailType = type.lable;
        },

        addEmail(email) {
            if (
                this.$data.email.data &&
                !validateSingleEmail(this.$data.email.data)
            ) {
                this.$data.email.error = true;
                this.email = {
                        data: email.data,
                        error: true,
                        errorMsg: "Enter valid email with max length 60 characters",
                    };
                return;
            } else if (
                this.$data.email.data &&
                validateSingleEmail(this.$data.email.data)
            ) {
                this.$data.email.error = false;

                if(this.$data.email.error == false){
                    
                let data = {
                    Email: email.data,
                    Type: this.switchEmailType,
                };
                let response = this.AddedEmailList.filter(
                    
                    (item) => item.Email.toLowerCase() == data.Email.toLowerCase() && item.Type == data.Type
                );
                if (response.length === 0) {
                    this.AddedEmailList.push(data);
                    this.email.data = "";
                    this.switchEmailType = "";
                    this.switchEmailId = 0;
                } else {
                    this.email = {
                        data: data.Email,
                        error: true,
                        errorMsg: "Email already exist",
                    };
                }
                }
            }

        },

        removeSingleEmail(value) {
            this.AddedEmailList.forEach((item, index) => {
                if (item == value) {
                    this.AddedEmailList.splice(index, 1);
                }
            });
        },

        triggerInputField(param) {
            if (param === "email") {
                this.$data.email.error = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.radioButtonGroup {
    display: inline-block;
    width: 50px;
    padding: 0px;
    margin-top: $mds-space-4-x;
}

.create_alert_addButton {
    margin-top: 30px;
}

.create_alert_emailList {
    width: 420px;
    overflow-y: scroll;
    margin-top: $mds-space-2-x;
    margin-left: $mds-space-quarter-x;
    max-height: 200px;
}

.create_email_alert_table {
    width: 400px;
}

.create_alerts_remove_single_email_list {
    float: right;
    margin-top: $mds-space-half-x;
}

.tableDataRow {
    padding: 2px 0px 2px 0px;
}

.tableDataRowType{
   padding: 2px 0px 2px 18px;
}

.create_alert_shared_with_heading {
    font-weight: bold;
    margin-bottom: $mds-space-half-x;
    margin-top: $mds-space-3-x;
}

.Create_alert_Email_section {
    height: 335px;
}

.create_alert_section_border {
    margin-top: $mds-space-3-x;
    border-bottom: 1px solid #cccccc;
    margin-bottom: $mds-space-1-x;
}

.Create_alert_Email_section_add_email_section {
    margin-top: $mds-space-2-x;
}
</style>