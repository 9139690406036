<template>
    <div>
        <div>
            <!-- alert name -->
            <mds-layout-grid>
                <mds-row class="create_alerts_name_section">
                    <mds-col :cols="4">
                        <mds-form>
                            <mds-fieldset>
                                <mds-input
                                    maxlength="250"
                                    label="Alert Name"
                                    placeholder="Enter a descriptive name"
                                    v-model.trim="alertName"
                                    :disabled="alertInputField"
                                ></mds-input>
                            </mds-fieldset>
                        </mds-form>
                    </mds-col>
                </mds-row>
                
            <span>
                <CreateAlertsEmailComponent
                    :AddedEmailList="AddedEmailList"
                ></CreateAlertsEmailComponent>
            </span>
            </mds-layout-grid>


            <!-- footer section -->
            <common-footer
                buttonName="Save"
                @cancelButton="cancelButton"
                @saveButton="saveButton"
                :isDisabled="!saveButtonDisable"
            >
            </common-footer>
            <loader-component v-if="showLoader"></loader-component>
        </div>
    </div>
</template>

<script>
import MdsForm from "@mds/form";
import MdsFieldset from "@mds/fieldset";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import CommonFooter from "../../data-dissemination/components/commonFooter.vue";
import CreateAlertsEmailComponent from "../components/CreateAlertsEmailComponent.vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsInput from "@mds/input";
export default {
    name: "CreateAlertsTPADelivery",
    components: {
        MdsForm,
        MdsFieldset,
        LoaderComponent,
        CommonFooter,
        CreateAlertsEmailComponent,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsInput,
    },
    data() {
        return {
            showLoader: true,
            toggleCancelButton: false,
            alertName: "",
            AddedEmailList: [],
            alertInputField: false,
        };
    },
    props: {
        getEditAlertData: {
            type: Object,
            default: () => {},
        },
    },
    async mounted() {
        if (Object.keys(this.getEditAlertData).length) {
            this.alertInputField = true;
            this.alertName = this.getEditAlertData.name;
            if(this.getEditAlertData.email != null){
               let ccResult = this.getEditAlertData.email.cc;
                if (ccResult != null) {
                    ccResult.forEach((item) => {
                        let data = {};
                        (data.Email = item),
                            (data.Type = "Cc"),
                            this.AddedEmailList.push(data);
                    });
                }

                let toResult = this.getEditAlertData.email.to;
                if (toResult != null) {
                    toResult.forEach((item) => {
                        let data = {};
                        (data.Email = item),
                            (data.Type = "To"),
                            this.AddedEmailList.push(data);
                    });
                }
            }
        }else {
            if (sessionStorage.getItem("permissions")) {
                var permissions = JSON.parse(
                    sessionStorage.getItem("permissions")
                );
                let arr = permissions.userEmail;
                let data = {};
                (data.Email = arr),
                    (data.Type = "To"),
                    this.AddedEmailList.push(data);
            }
        }
        this.showLoader = false;
    },
    computed: {
        saveButtonDisable() {
            return this.alertName && this.AddedEmailList.length;
        },
    },
    methods: {
        // save button
        saveButton() {
            if (Object.keys(this.getEditAlertData).length) {
                this.$emit("updateAlert", this.alertName, this.AddedEmailList);
            } else {
                this.$emit("saveAlert", this.alertName, this.AddedEmailList);
            }
        },
        // cancel button in footer
        cancelButton() {
            this.toggleCancelButton = !this.toggleCancelButton;
            this.$router.push('/settings/alerts');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.create_alerts_name_section {
    margin-top: $mds-space-2-x;
}
</style>