<template>
    <div id="createAlert">
        <div class="create_alert">
            <mds-layout-grid class="create_alert">
                <mds-row>
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails('/settings/alerts'),
                            }"
                        >
                            Alerts
                        </router-link>
                    </span>
                    <span class="create_alert_router">
                        / {{ alertHeaderTitle }}
                    </span>
                </mds-row>
                <mds-row class="create_alert_heading">
                    {{ alertHeaderTitle }}
                </mds-row>

                <mds-row class="create_alert_type_heading">Alert Type</mds-row>
                <mds-row class="create_alert_type_name"
                    v-if="Object.keys(this.getEditAlertData).length"
                >{{alertTypeHeading}}</mds-row>
                <!-- radio button for alert type -->
                <mds-row class="create_alert_type_name" v-if="Object.keys(this.getEditAlertData).length == 0">
                    <mds-form>
                        <mds-fieldset variation="radio-group">
                            <span v-if="!isAdminUser">
                                <mds-radio-button
                                    class="radioButtonGroup"
                                    name="group"
                                    v-for="alertTypes in getAlertType"
                                    :key="alertTypes.id"
                                    v-model="switchAlertType"
                                    :value="alertTypes.id"
                                    :label="alertTypes.lable"
                                    @input="switchAlertTypes(alertTypes)"
                                ></mds-radio-button>
                            </span>
                            <span v-if="isAdminUser">
                                <mds-radio-button
                                    class="radioButtonGroup"
                                    name="group"
                                    v-for="alertTypes in getAdminAlertType"
                                    :key="alertTypes.id"
                                    v-model="switchAlertType"
                                    :value="alertTypes.id"
                                    :label="alertTypes.lable"
                                    @input="switchAlertTypes(alertTypes)"
                                ></mds-radio-button>
                            </span>
                        </mds-fieldset>
                    </mds-form>
                </mds-row>
            </mds-layout-grid>

            <!-- middle section -->
            <mds-layout-grid
                class="create_alert_middle_section"
                v-if="this.selectedAlertTypeId != 0"
            >
                <div class="create_alert_section_border"></div>

                <!-- middle section depend on radio button -->
                <!-- for alert Type Id = 1 -->
                <span v-if="this.selectedAlertTypeId == 1">
                    <CreateAlertsItemsChangesContainer
                        :getEditAlertData="getEditAlertData"
                        :alertId="alertId"
                    ></CreateAlertsItemsChangesContainer>
                </span>

                <!-- for alert Type Id = 2 -->
                <span v-if="this.selectedAlertTypeId == 2">
                    <CreateAlertsNewEntitiesContainer
                        :getEditAlertData="getEditAlertData"
                        :alertId="alertId"
                    ></CreateAlertsNewEntitiesContainer>
                </span>

                <!-- for alert Type Id = 3 -->
                <span v-if="this.selectedAlertTypeId == 3">
                    <CreateAlertsTPADeliveryContainer
                        :getEditAlertData="getEditAlertData"
                        :alertId="alertId"
                    ></CreateAlertsTPADeliveryContainer>
                </span>
            </mds-layout-grid>
        </div>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import store from "@/store";
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsFieldset from "@mds/fieldset";
import MdsRadioButton from "@mds/radio-button";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import CreateAlertsNewEntitiesContainer from "../container/CreateAlertsNewEntitiesContainer.vue";
import CreateAlertsItemsChangesContainer from "../container/CreateAlertsItemsChangeContainer.vue";
import CreateAlertsTPADeliveryContainer from "../container/CreateAlertsTPADeliveryContainer.vue";

export default {
    name: "CreateAlerts",
    beforeCreate() {
        this.$store = store;
    },
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsForm,
        MdsFieldset,
        MdsRadioButton,
        LoaderComponent,
        CreateAlertsNewEntitiesContainer,
        CreateAlertsItemsChangesContainer,
        CreateAlertsTPADeliveryContainer,
    },
    data() {
        return {
            showLoader: true,
            isAdminUser: false,
            switchAlertType: 0,
            selectedAlertTypeId: 0,
            alertTypeHeading: "",
        };
    },

    props: {
        alertHeaderTitle: {
            type: String,
            default: "",
        },
        getAdminAlertType: {
            type: Array,
            default: () => [],
        },
        alertInputField: {
            type: Boolean,
        },
        getAlertType: {
            type: Array,
            default: () => [],
        },
        getEditAlertData: {
            type: Object,
            default: () => {},
        },
        alertId: {
            type: Number,
            default: 0,
        },
    },

    async mounted() {
        const getAllPermissions = this.$store.state.auth.permissions;
        const checkAdmin = getAllPermissions.includes('alert-email-section') || getAllPermissions.includes('*');
        this.isAdminUser = checkAdmin;
        if (Object.keys(this.getEditAlertData).length) {
            this.switchAlertType = this.getEditAlertData.notificationtypeid;
            this.selectedAlertTypeId = this.getEditAlertData.notificationtypeid;
            if (this.switchAlertType == 1) {
                this.alertTypeHeading = "Alert you when items change";
            } else if (this.switchAlertType == 2) {
                this.alertTypeHeading = "Alert you when new entities are added";
            } else {
                this.alertTypeHeading = "TPA delivery marked late";
            }
        }
        this.showLoader = false;
    },

    computed: {},

    methods: {
        // route to alert screen
        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },

        // switch radio button
        switchAlertTypes(type) {
            this.selectedAlertTypeId = type.id;
            this.$emit("switchAlertTypes", type);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.create_alert {
    padding: 8px 16px 0 16px;
}
.create_alert_heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
}
.create_alert_router {
    margin-left: $mds-space-half-x;
}

.create_alert_type_heading {
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
}

.radioButtonGroup {
    display: inline-block;
    width: 280px;
    padding: 0px;
    margin-top: $mds-space-half-x;
}

.create_alert_section_border {
    margin-top: $mds-space-3-x;
    border-bottom: 1px solid #cccccc;
}

.create_alert_section_Middle_section_border {
    margin: 8px 8px 0 8px;
    border-bottom: 1px solid #cccccc;
}

.create_alert_type_name{
    padding-top: 10px;
}
</style>