<template>
    <div>
        <CreateAlertsNewEntities
            :EntityLevels="EntityLevels"
            :dropdownDomicileList="dropdownDomicileList"
            :selectLevelId="selectLevelId"
            :getEditAlertData="getEditAlertData"
            :isAdminUser="isAdminUser"
            @selectLevel="selectLevel"
            @saveAlert="saveAlert"
            @updateAlert="updateAlert"
            :key="newEntityKey"
        ></CreateAlertsNewEntities>
        <loader-component v-if="showLoader"></loader-component>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import CreateAlertsNewEntities from "../components/CreateAlertsNewEntities.vue";
import get from "lodash/get";
import { mapGetters, mapState, mapActions } from "vuex";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import { ALERTS_ACTIONS } from "@/store/modules/alerts/contants";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    name: "CreateAlertsNewEntitiesContainer",
    components: {
        CreateAlertsNewEntities,
        LoaderComponent,
        NotificationComponent,
    },
    data() {
        return {
            showLoader: false,
            dropdownDomicileList:[],
            selectLevelId: [],
            ToEmailList: [],
            CcEmailList: [],
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            isAdminUser: false,
            EntityLevels:[],
            newEntityKey:0,
        };
    },
    props: {
        getEditAlertData: {
            Type: Object,
            default: {},
        },
        alertId: {
            type: Number,
            default: 0,
        },
    },
    async created(){
    },

    async mounted() {
        this.showLoader = true;
        await this.fetchEntityTypes();
        this.getEntityLevelList();
        await this.fetchSelectDropDownForEnumId(10, []);
        let response = this.getDropDownsForEnumIds[0];
        this.dropdownDomicileList = response.enumDropDowns;
        const getAllPermissions = this.$store.state.auth.permissions;
        const checkAdmin = getAllPermissions.includes('alert-email-section')|| getAllPermissions.includes('*');
        this.isAdminUser = checkAdmin;
        if (Object.keys(this.getEditAlertData).length) {
            this.selectLevelId = this.getEditAlertData.config.level;
            this.selectLevelId.forEach((item) => {
                this.EntityLevels.forEach((element) => {
                    if (element.entitytypeid.toString() == item) {
                        element.isSelected = true;
                    }
                });
            });
        }
        this.newEntityKey+=1;
        this.showLoader = false;
    },
    computed: {
        ...mapGetters("entity", ["getEntityTypes", "getDropDownsForEnumIds"]),
        ...mapState({
            alertSave(state) {
                return get(state, "alerts.saveAlerts.saveAlerts", []);
            },
            alertSaveError(state) {
                return get(state, "alerts.saveAlerts.__error__", null);
            },
            alertUpdate(state) {
                return get(state, "alerts.updateAlerts.updateAlerts", []);
            },
            alertUpdateError(state) {
                return get(state, "alerts.updateAlerts.__error__", null);
            },
            
        }),
    },
    methods: {
        ...mapActions("entity", [
            "fetchEntityTypes",
            "fetchSelectDropDownForEnumId",
        ]),

        getEntityLevelList() {
            this.showLoader = true;
            this.getEntityTypes.forEach((item) => {
                item.isSelected = false;
                this.EntityLevels.push(item);
            });
            this.showLoader = false;
        },

        selectLevel(event, level) {
            let id = level.entitytypeid.toString();
            if (event == true) {
                level.isSelected = true;
                this.selectLevelId.push(id);
            } else if (event == false) {
                level.isSelected = false;
                this.selectLevelId.forEach((item, index) => {
                    if (item == id) {
                        this.selectLevelId.splice(index, 1);
                    }
                });
            }
        },

        async saveAlert(alertname, selectedDomicile, emailList) {
            let data = {};
            let config = {};
            let email = {};
            data.name = alertname;
            data.notificationTypeId = 2;
            config.domicile = selectedDomicile;
            config.level = this.selectLevelId;
            data.config = config;

            if (emailList.length > 0) {
                let toEmail = emailList.filter(
                    (result) => result.Type === "To"
                );
                this.ToEmailList = toEmail.map((item) => item.Email);

                let ccEmail = emailList.filter(
                    (result) => result.Type === "Cc"
                );
                this.CcEmailList = ccEmail.map((item) => item.Email);
                email.to = this.ToEmailList;
                email.cc = this.CcEmailList;
                data.email = email;
            }

            if (this.isAdminUser == true && emailList.length == 0) {
                email.to = [];
                email.cc = [];
                data.email = email;
            }

            await this.$store.dispatch(ALERTS_ACTIONS.FETCH_SAVE_ALERT, {
                data,
            });
            let response = this.alertSave;
            const obj = {
                response,
                ntfnMsg: "The alert has been created.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.alertSaveError,
            };
            this.notificationResponse(obj);
        },

        async updateAlert(alertname, selectedDomicile, emailList) {
            let data = {};
            let config = {};
            let email = {};
            data.notificationRuleId = this.alertId;
            data.name = alertname;
            data.notificationTypeId = 2;
            config.domicile = selectedDomicile;
            config.level = this.selectLevelId;
            data.config = config;

            if (emailList.length > 0) {
                let toEmail = emailList.filter(
                    (result) => result.Type === "To"
                );
                this.ToEmailList = toEmail.map((item) => item.Email);

                let ccEmail = emailList.filter(
                    (result) => result.Type === "Cc"
                );
                this.CcEmailList = ccEmail.map((item) => item.Email);
                email.to = this.ToEmailList;
                email.cc = this.CcEmailList;
                data.email = email;
            }

            if (this.isAdminUser == true && emailList.length == 0) {
                email.to = [];
                email.cc = [];
                data.email = email;
            }

            await this.$store.dispatch(ALERTS_ACTIONS.FETCH_UPDATE_ALERT, {
                data,
            });
            let response = this.alertUpdate;
            const obj = {
                response,
                ntfnMsg: "The alert has been updated.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.alertUpdateError,
            };
            this.notificationResponse(obj);
        },

        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 201 || response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
            if (!ntfnfailureMsg) {
                setTimeout(() => {
                    this.$router.push({
                        name: "Settings Alert",
                    });
                }, 2000);
            }
        },
    },
};
</script>
