<template>
    <div>
        <div>
            <mds-layout-grid class="create_alert_item_change_middle_section">
                <!-- alert name -->
                <mds-row class="create_alerts_name_section">
                    <mds-col :cols="4">
                        <mds-form>
                            <mds-fieldset>
                                <mds-input
                                    maxlength="250"
                                    label="Alert Name"
                                    placeholder="Enter a descriptive name"
                                    v-model.trim="alertName"
                                    :disabled="alertInputField"
                                ></mds-input>
                            </mds-fieldset>
                        </mds-form>
                    </mds-col>
                </mds-row>

                <mds-row class="create_alerts">
                    <!-- Universe Dropdown -->
                    <mds-col :cols="4">
                        <mds-form>
                        <mds-select
                            label="Universe"
                            placeholder="Select Universe"
                            v-model="universe"
                            @change="changeUniverse"
                        >
                            <option
                            name="group1"
                                v-for="(item, index) in dropDownUniverseList"
                                :key="index"
                                :value="item.listid"
                                :selected="
                                    selectedUniverseId === parseInt(item.listid)
                                "
                            >
                                {{ item.listdescription }}
                            </option>
                        </mds-select>
                        </mds-form>
                    </mds-col>

                    <!-- Dataset Dropdown -->
                    <mds-col :cols="4">
                        <mds-form>
                        <mds-select
                            label="Data Set"
                            placeholder="Select Data Set"
                            v-model="dataset"
                            @change="changeDataset"
                        >
                            <option
                                v-for="(item, index) in dropDownDatasetList"
                                :key="index"
                                :value="item.datasetid"
                                :selected="
                                    selectedDatasetId === parseInt(item.datasetid)
                                "
                            >
                                {{ item.datasetname }}
                            </option>
                        </mds-select>
                        </mds-form>
                    </mds-col>
                </mds-row>
                <span v-if="isAdminUser">
                    <CreateAlertsEmailComponent
                        :AddedEmailList="AddedEmailList"
                    ></CreateAlertsEmailComponent>
                </span>
            </mds-layout-grid>

            <!-- footer section -->
            <common-footer
                buttonName="Save"
                @cancelButton="cancelButton"
                @saveButton="saveButton"
                :isDisabled="!saveButtonDisable"
            >
            </common-footer>
        </div>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsSelect from "@mds/select";
import CommonFooter from "../../data-dissemination/components/commonFooter.vue";
import CreateAlertsEmailComponent from "../components/CreateAlertsEmailComponent.vue";
import MdsInput from "@mds/input";
import MdsFieldset from "@mds/fieldset";

export default {
    name: "CreateAlertsItemsChanges",
    components: {
        LoaderComponent,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsSelect,
        CommonFooter,
        CreateAlertsEmailComponent,
        MdsInput,
        MdsFieldset,
    },
    data() {
        return {
            showLoader: true,
            universe: '',
            dataset: '',
            selectedUniverseId: 0,
            selectedDatasetId: 0,
            toggleCancelButton: false,
            alertName: "",
            AddedEmailList: [],
            alertInputField:false,
        };
    },
    props: {
        dropDownUniverseList: {
            type: Array,
            default: () => [],
        },
        dropDownDatasetList: {
            type: Array,
            default: () => [],
        },
        getEditAlertData: {
            type: Object,
            default: () => {},
        },
        isAdminUser:{
            type: Boolean,
            default: false,
        },
    },
    async mounted() {
        if(Object.keys(this.getEditAlertData).length){
        this.alertInputField = true;    
        this.alertName = this.getEditAlertData.name;
        this.selectedUniverseId = this.getEditAlertData.config.universeid;
        this.universe = this.selectedUniverseId;
        this.selectedDatasetId = this.getEditAlertData.config.datasetid;
        this.dataset = this.getEditAlertData.config.datasetid;
        if(this.getEditAlertData.email != null){
           let ccResult = this.getEditAlertData.email.cc;
            if (ccResult != null) {
            ccResult.forEach(item => {
                let data={}
                    data.Email = item,
                    data.Type = 'Cc',
                    this.AddedEmailList.push(data);
            });
            }

            let toResult = this.getEditAlertData.email.to;
            if (toResult != null) {
            toResult.forEach(item => {
                let data={}
                    data.Email = item,
                    data.Type = 'To',
                    this.AddedEmailList.push(data);
            });
            }
        }
        }else {
            if (sessionStorage.getItem("permissions")) {
                var permissions = JSON.parse(
                    sessionStorage.getItem("permissions")
                );
                let arr = permissions.userEmail;
                let data = {};
                (data.Email = arr),
                    (data.Type = "To"),
                    this.AddedEmailList.push(data);
            }
        }
        this.showLoader = false;
    },
    computed: {
        saveButtonDisable() {
            return this.alertName && this.universe && this.dataset && this.AddedEmailList.length;
        },
    },
    methods: {
        changeUniverse() {
            this.$emit("editUniverseData", this.universe);
        },

        changeDataset() {
            this.$emit("editDatasetData", this.dataset);
        },

        // save button
        saveButton() {
            if(Object.keys(this.getEditAlertData).length){
               this.$emit("updateAlert", this.alertName, this.AddedEmailList); 
            }
            else{
            this.$emit("saveAlert", this.alertName, this.AddedEmailList);
            }
        },
        // cancel button in footer
        cancelButton() {
            this.toggleCancelButton = !this.toggleCancelButton;
            this.$router.push('/settings/alerts');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";
.create_alerts {
    margin-top: $mds-space-2-x;
}

.create_alert_item_change_middle_section {
    height: 500px;
}

.create_alerts_name_section {
    margin-top: $mds-space-2-x;
}

</style>