<template>
    <div>
        <CreateAlertsItemsChanges
            v-if="dropDownUniverseList.length"
            :dropDownDatasetList="dropDownDatasetList"
            :dropDownUniverseList="dropDownUniverseList"
            :getEditAlertData="getEditAlertData"
            :alertId="alertId"
            :isAdminUser="isAdminUser"
            @editUniverseData="editUniverseData"
            @editDatasetData="editDatasetData"
            @saveAlert="saveAlert"
            @updateAlert="updateAlert"
        ></CreateAlertsItemsChanges>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import CreateAlertsItemsChanges from "../components/CreateAlertsItemsChange.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import { ALERTS_ACTIONS } from "@/store/modules/alerts/contants";
import get from "lodash/get";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    name: "CreateAlertsItemsChangesContainer",
    components: {
        CreateAlertsItemsChanges,
        LoaderComponent,
        NotificationComponent,
    },
    data() {
        return {
            showLoader: true,
            dropDownDatasetList: [],
            dropDownUniverseList: [],
            universeId: 0,
            dataSetId: 0,
            ToEmailList: [],
            CcEmailList: [],
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            isAdminUser:false,
        };
    },
    props: {
        getEditAlertData:{
            Type:Object,
            default:{}
        },
        alertId:{
            type: Number,
            default: 0,
        }
    },
    async mounted() {
        await this.commonDatasetFunction();
        await this.commonUniverseFunction();
        const getAllPermissions = this.$store.state.auth.permissions;
        const checkAdmin = getAllPermissions.includes('alert-email-section')|| getAllPermissions.includes('*');
        this.isAdminUser = checkAdmin;
        if(Object.keys(this.getEditAlertData).length){
        this.universeId = this.getEditAlertData.config.universeid;
        this.dataSetId = this.getEditAlertData.config.datasetid;
        }
        this.showLoader = false;
    },
    computed: {
        ...mapGetters("reporting", ["getDataSets", "getUniverses"]),
        ...mapState({
            alertSave(state) {
                return get(state, "alerts.saveAlerts.saveAlerts", []);
            },
            alertSaveError(state) {
                return get(state, "alerts.saveAlerts.__error__", null);
            },
            alertUpdate(state) {
                return get(state, "alerts.updateAlerts.updateAlerts", []);
            },
            alertUpdateError(state) {
                return get(state, "alerts.updateAlerts.__error__", null);
            },
        }),
    },
    methods: {
        ...mapActions("reporting", ["fetchDataSetsList", "fetchUniversesList"]),

        async commonDatasetFunction() {
            this.showLoader = true;
            var obj = {
                Page: 1,
                pageSize: 0,
            };
            await this.fetchDataSetsList(obj);
            let response = this.getDataSets;
            this.dropDownDatasetList = response.dataset;
            this.showLoader = false;
        },

        async commonUniverseFunction() {
            this.showLoader = true;
            var obj = {
                page: 1,
                pageSize: 0,
            };
            await this.fetchUniversesList(obj);
            let response = this.getUniverses;
            this.dropDownUniverseList = response.universelist;
            this.showLoader = false;
        },

        editUniverseData(val) {
            this.universeId = val;
        },

        editDatasetData(val) {
            this.dataSetId = val;
        },

        async saveAlert(alertname, emailList) {
            let data = {};
            let config = {};
            let email = {};
            data.name = alertname;
            data.notificationTypeId = 1;
            config.universeId = this.universeId;
            config.dataSetId = this.dataSetId;
            data.config = config;

            if(emailList.length > 0){
            let toEmail = emailList.filter((result) => result.Type === "To");
            this.ToEmailList = toEmail.map((item) => item.Email);

            let ccEmail = emailList.filter((result) => result.Type === "Cc");
            this.CcEmailList = ccEmail.map((item) => item.Email);
            
            email.to = this.ToEmailList;
            email.cc = this.CcEmailList;
            data.email = email;
            }

            if(this.isAdminUser == true && emailList.length == 0){
               email.to = []; 
               email.cc = []; 
               data.email = email;
            }

            await this.$store.dispatch(
                    ALERTS_ACTIONS.FETCH_SAVE_ALERT,
                    {
                        data,
                    }
                );
                let response = this.alertSave;
                const obj = {
                    response,
                    ntfnMsg: "The alert has been created.",
                    ntfnType: "success",
                    ntfnKey: "success-default",
                    ntfnfailureMsg: this.alertSaveError,
                };
                this.notificationResponse(obj);
        },

        
        async updateAlert(alertname, emailList) {
            let data = {};
            let config = {};
            let email = {};
            data.notificationRuleId = this.alertId;
            data.name = alertname;
            data.notificationTypeId = 1;
            config.universeId = this.universeId;
            config.dataSetId = this.dataSetId;
            data.config = config;

            if(emailList.length > 0){
            let toEmail = emailList.filter((result) => result.Type === "To");
            this.ToEmailList = toEmail.map((item) => item.Email);

            let ccEmail = emailList.filter((result) => result.Type === "Cc");
            this.CcEmailList = ccEmail.map((item) => item.Email);
            let email = {};
            email.to = this.ToEmailList;
            email.cc = this.CcEmailList;
            data.email = email;
            }

            if(this.isAdminUser == true && emailList.length == 0){
               email.to = []; 
               email.cc = []; 
               data.email = email;
            }

            await this.$store.dispatch(
                    ALERTS_ACTIONS.FETCH_UPDATE_ALERT,
                    {
                        data,
                    }
                );
                let response = this.alertUpdate;
                const obj = {
                    response,
                    ntfnMsg: "The alert has been updated.",
                    ntfnType: "success",
                    ntfnKey: "success-default",
                    ntfnfailureMsg: this.alertUpdateError,
                };
                this.notificationResponse(obj);
        },

        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 201 || response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
            if (!ntfnfailureMsg) {
                setTimeout(() => {
                    this.$router.push({
                        name: "Settings Alert",
                    });
                }, 2000);
            }
        },
    },
};
</script>
