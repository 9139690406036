<template>
    <div>
        <CreateAlertsTPADelivery
            :getEditAlertData="getEditAlertData"
            :alertId="alertId"
            @saveAlert="saveAlert"
            @updateAlert="updateAlert"
        ></CreateAlertsTPADelivery>
        <loader-component v-if="showLoader"></loader-component>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import CreateAlertsTPADelivery from "../components/CreateAlertsTPADelivery.vue";
import get from "lodash/get";
import { mapState } from "vuex";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import { ALERTS_ACTIONS } from "@/store/modules/alerts/contants";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    name: "CreateAlertsTPADeliveryContainer",
    components: {
        CreateAlertsTPADelivery,
        LoaderComponent,
        NotificationComponent,
    },
    data() {
        return {
            showLoader: false,
            ToEmailList: [],
            CcEmailList: [],
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            isAdminUser:true,
        };
    },
    props: {
        getEditAlertData: {
            Type: Object,
            default: {},
        },
        alertId: {
            type: Number,
            default: 0,
        },
    },
    async mounted() {},
    computed: {
        ...mapState({
            alertSave(state) {
                return get(state, "alerts.saveAlerts.saveAlerts", []);
            },
            alertSaveError(state) {
                return get(state, "alerts.saveAlerts.__error__", null);
            },
            alertUpdate(state) {
                return get(state, "alerts.updateAlerts.updateAlerts", []);
            },
            alertUpdateError(state) {
                return get(state, "alerts.updateAlerts.__error__", null);
            },
        }),
    },
    methods: {
        async saveAlert(alertname, emailList) {
            let data = {};
            let email = {};
            data.name = alertname;
            data.notificationTypeId = 3;

            if (emailList.length > 0) {
                let toEmail = emailList.filter(
                    (result) => result.Type === "To"
                );
                this.ToEmailList = toEmail.map((item) => item.Email);

                let ccEmail = emailList.filter(
                    (result) => result.Type === "Cc"
                );
                this.CcEmailList = ccEmail.map((item) => item.Email);

                email.to = this.ToEmailList;
                email.cc = this.CcEmailList;
                data.email = email;
            } else {
                email.to = [];
                email.cc = [];
                data.email = email;
            }

            await this.$store.dispatch(ALERTS_ACTIONS.FETCH_SAVE_ALERT, {
                data,
            });
            let response = this.alertSave;
            const obj = {
                response,
                ntfnMsg: "The alert has been created.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.alertSaveError,
            };
            this.notificationResponse(obj);
        },

        async updateAlert(alertname, emailList) {
            let data = {};
            let email = {};
            data.notificationRuleId = this.alertId;
            data.name = alertname;
            data.notificationTypeId = 3;

            if (emailList.length > 0) {
                let toEmail = emailList.filter(
                    (result) => result.Type === "To"
                );
                this.ToEmailList = toEmail.map((item) => item.Email);

                let ccEmail = emailList.filter(
                    (result) => result.Type === "Cc"
                );
                this.CcEmailList = ccEmail.map((item) => item.Email);
                email.to = this.ToEmailList;
                email.cc = this.CcEmailList;
                data.email = email;
            }else {
                email.to = [];
                email.cc = [];
                data.email = email;
            }


            await this.$store.dispatch(ALERTS_ACTIONS.FETCH_UPDATE_ALERT, {
                data,
            });
            let response = this.alertUpdate;
            const obj = {
                response,
                ntfnMsg: "The alert has been updated.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.alertUpdateError,
            };
            this.notificationResponse(obj);
        },

        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 201 || response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
            if (!ntfnfailureMsg) {
                setTimeout(() => {
                    this.$router.push({
                        name: "Settings Alert",
                    });
                }, 2000);
            }
        },
    },
};
</script>
